import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { getOrder, finishOrder, selectOrder, releaseOrder, printErrorLabel, sendProductShortages, returnToHome, finishOrderSkipSummary } from '../actions/PackActions'
import { selectShipping, deleteShipping } from '../actions/ShippingActions'
import ProgressBar from './ProgressBar'
import ProductListItem from './ProductListItem'
import { b64EncodeUnicode } from '../utils/Tools'
import { ReactComponent as Check } from '../assets/check.svg'
import sound_not_found from '../assets/not_found_audio.mp3'
import sound_correct_scan from '../assets/correct_scan.mp3'
import sound_finish_packing from '../assets/finish_packing_audio.mp3'
import sound_speaky_peaky_one from '../assets/sneaky_peaky_1.mp3'
import sound_speaky_peaky_two from '../assets/sneaky_peaky_2.mp3'
import pack from '../reducers/pack'
require('./ShippingList')
require('./MaterialsList')
require('./Substitutes')

function getProductListItem(prod, parent, group_treshold) {
  // render substitutes
  const orderSubstitutes = parent.getOrderSubstitutes(prod.line_id) || {}
  const product = {
    ...prod,
    ...(prod.substitute_products ? {
      substitute_products: prod.substitute_products.map(sub => ({
        ...sub,
        value: (orderSubstitutes || {})[sub.product_id]
      }))
    } : {})
  }
  if(product.substitute_products && product.substitute_products.some(sub => (sub.value && sub.value > 0))){
    let arr = []
    product.substitute_products.filter(sub => (sub.value && sub.value > 0)).forEach(sub => {
      arr.push(<ProductListItem
                key={product.line_id+'.'+sub.product_id}
                product={{
                  ...product,
                  default_code: sub.default_code,
                  quote_default_code: sub.default_code,
                  product_name: sub.description,
                  product_id: sub.product_id,
                  product_uom_qty: sub.value,
                  line_qty: product.product_uom_qty
                }}
                qty={sub.value}
                supercount={true}
                productTracking={false}
                parent={parent}/>
      )
    })
    return arr
  }
  // render substitutes END

  const productTracking = product.product_tracking !== 'none'
  let supercount = productTracking ? false : product.product_uom_qty >= group_treshold
  let k = 1
  if (supercount){
    k++
    return (<ProductListItem
             key={product.line_id+'.'+product.product_id+'.'+k}
             product={product}
             qty={product.product_uom_qty}
             supercount={supercount}
             parent={parent}/>
    )
  } else {
    let arr = []
    for (var i = 0; i < product.product_uom_qty; i++) {
      k++
      arr.push(<ProductListItem
                key={product.line_id+'.'+product.product_id+'.'+k}
                product={product}
                qty={(product.product_uom_qty - i) < 1 ? Math.round((product.product_uom_qty - i)*100)/100 : 1}
                supercount={supercount}
                productTracking={productTracking}
                parent={parent}/>
      )
    }
    return arr
  }

}

const inpost_size_options = [
  ['A','A'],
  ['B','B'],
  ['C','C'],
]

const orlen_size_options = [
  ['S','S'],
  ['M','M'],
  ['L','L'],
  ['Mini','MINI'],
]

const pp_size_options = [
  ['XS','xsmall'],
  ['S','small'],
  ['M','medium'],
  ['L','large'],
  ['XL','xlarge'],
  ['XXL','xxlarge'],
]

const wza_size_options = [
  ['S','s'],
  ['M','m'],
  ['L','l'],
]

const wrong_ean_audio = new Audio(sound_not_found);
const correct_scan_audio = new Audio(sound_correct_scan);
const finish_packing_audio = new Audio(sound_finish_packing);

const speaky_peaky_one_audio = new Audio(sound_speaky_peaky_one);
const speaky_peaky_two_audio = new Audio(sound_speaky_peaky_two);

const is_local_host_or_testerp = window.location.href.includes("localhost") || window.location.href.includes("testerp13-dev");


const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  product_confirmation_method: state.config.product_confirmation_method,
  auto_select_first: state.config.auto_select_first,
  verify_qty: !!state.config.verify_qty,
  confirm_note: state.config.confirm_note,
  show_company: state.config.show_company,
  show_shop: state.config.show_shop,
  carrier_selection: state.config.carrier_selection,
  inventory_manage_materials: state.config.inventory_manage_materials,
  use_packing_zone_error_label: state.config.use_packing_zone_error_label,
  material_products: state.config.material_products,
  auto_check_all_products: state.config.auto_check_all_products,
  product_shortages_in_packing_zone: state.config.product_shortages_in_packing_zone,
  inpost_dostawy: state.config.inpost_dostawy || [],
  pp_dostawy: state.config.pp_dostawy || [],
  wza_dostawy: state.config.wza_dostawy || [],
  orlen_dostawy: state.config.orlen_dostawy || [],
  add_package: state.config.add_package,
  add_delivery: state.config.add_delivery,
  group_treshold: state.config.group_treshold ? parseInt(state.config.group_treshold) : false,
  pack: state.router.location.query.pack ? parseInt(state.router.location.query.pack) : false,
  order_id: state.router.location.query.order ? parseInt(state.router.location.query.order) : false,
  order: state.pack.order,
  processing: state.pack.processing,
  last_scanned: state.pack.last_scanned,
  shipping: state.shipping.shipping,
  inventory_materials_shipment_package_sizes: state.config.inventory_materials_shipment_package_sizes,
  shipping_data: state.shipping.shipping_data,
  available_carriers: state.config.available_carriers,
  show_package_button_in_packing_zone: state.config.show_package_button_in_packing_zone,
  scan_bundle_element_as_single_qty: state.config.scan_bundle_element_as_single_qty,
  packing_zone_dont_show_summary: state.config.packing_zone_dont_show_summary
})

const mapDispatchToProps = dispatch => ({
  getOrderAction: (code, id) => dispatch(getOrder(code, id)),
  finishOrderAction: (data) => dispatch(finishOrder(data)),
  sendProductShortages: data => dispatch(sendProductShortages(data)),
  selectOrderAction: () => dispatch(selectOrder()),
  selectShippingAction: (id) => dispatch(selectShipping(id)),
  deleteShippingAction: (id) => dispatch(deleteShipping(id)),
  releaseOrderAction: (order_id) => dispatch(releaseOrder(order_id)),
  printErrorLabelAction: (order_id) => dispatch(printErrorLabel(order_id)),
  returnToHomeAction: () => dispatch(returnToHome()),
  finishOrderSkipSummaryAction: (data) => dispatch(finishOrderSkipSummary(data))
})

class ParcelTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      selectedRowIndex: 0,
      isSelectDisabled: false,
      parcelSizes: props.order.parcel_sizes || []
    };
  }

  componentDidMount() {
    this.defaultParcelSizes();
  }

  defaultParcelSizes = () => {
    const { order } = this.props;
    const parcels = order.shipping_order_ids[0].shipping_parcels_ids;

    const parcelData = parcels.map((parcel, index) => ({
      id: index + 1,
      weight: parcel.weight,
      height: parcel.height,
      length: parcel.length,
      width: parcel.width,
      size: parcel.size
    }));
    this.setState({ rows: parcelData });
  };


  handleInputChange = (e, index) => {
    const { name, value } = e.target;
  
    this.setState(prevState => {
      const updatedRows = [...prevState.rows];
      updatedRows[index][name] = value;
    });
  };
  
  addRow = () => {
    const { rows } = this.state;
    const newRow = { id: rows.length + 1, weight: '', height: '', length: '', width: '', size: '' };
    this.setState({ rows: [...rows, newRow] });
  };

  deleteRow = (index) => {
    const { rows, selectedRowIndex } = this.state;
    const isDeletingSelectedRow = selectedRowIndex === index;
    rows.splice(index, 1);
    rows.forEach((row, idx) => {
      const newRow = { ...row };
      newRow.id = idx + 1;
      rows[idx] = newRow;
    });
    const newSelectedIndex = isDeletingSelectedRow ? null : selectedRowIndex > index ? selectedRowIndex - 1 : selectedRowIndex;
    this.setState({ rows, selectedRowIndex: newSelectedIndex });
  };

  selectRow = (index) => {
    this.setState({ selectedRowIndex: index });
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  availableCarriers = () => {
    const { available_carriers, order} = this.props;
    const defaultCarrier = order.carrier_id

    available_carriers.map(carrier => {
      //console.log('ID:', carrier[0]);
      //console.log('Name:', carrier[1]);
    });

    return (
      <select name="carrier_id" onChange={this.carrierChange} defaultValue={defaultCarrier}>
        {available_carriers.map(carrier => (
          <option key={carrier[0]} value={carrier[0]}>{carrier[1]}</option>
        ))}
      </select>
    );
  }
  
  carrierChange = (event) => {
    const { available_carriers } = this.props;
    const selectedCarrierId = event.target.value;
  
    if (available_carriers[selectedCarrierId]) {
      const parcelSizes = available_carriers[selectedCarrierId][3];
      
      this.setState({ parcelSizes: parcelSizes, isSelectDisabled: parcelSizes.length === 0 });
    }
  }

  render() {
    return (
      <div className="carrier f_group inventory_materials_shipment">
        <div className="carriers-container">
          {this.availableCarriers()}
        </div>
        <table className="parcels table">
          <thead>
            <tr>
              <th>#</th>
              <th className="right">{intl.get('Waga')}</th>
              <th className="right">{intl.get('Wysokość')}</th>
              <th className="right">{intl.get('Długość')}</th>
              <th className="right">{intl.get('Szerokość')}</th>
              <th className="right">{intl.get('Rozmiar')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.rows.map((row, index) => (
              <tr key={row.id} className={index === this.state.selectedRowIndex ? "selected" : ""} onClick={() => this.selectRow(index)}>
                <td>{row.id}</td>
                <td><input type="number" step="1" name={`weight_${row.id}`} defaultValue={row.weight} onBlur={(e) => this.handleInputChange(e, index)} onClick={this.stopPropagation} /></td>
                <td><input type="number" step="1" name={`height_${row.id}`} defaultValue={row.height} onBlur={(e) => this.handleInputChange(e, index)} onClick={this.stopPropagation} /></td>
                <td><input type="number" step="1" name={`length_${row.id}`} defaultValue={row.length} onBlur={(e) => this.handleInputChange(e, index)} onClick={this.stopPropagation} /></td>
                <td><input type="number" step="1" name={`width_${row.id}`} defaultValue={row.width} onBlur={(e) => this.handleInputChange(e, index)} onClick={this.stopPropagation} /></td>
                <td>
                  <select name={`size_${row.id}`} defaultValue={row.size} onClick={this.stopPropagation} onChange={(e) => this.handleInputChange(e, index)} disabled={this.state.parcelSizes.length === 0}>
                    {this.state.parcelSizes.map((size, sizeIndex) => (
                      <option key={sizeIndex} value={size}>{size[1]}</option>
                    ))}
                  </select>
                </td>
                <td><button onClick={() => this.deleteRow(index)}>Usuń</button></td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="addRow" id="addRowButton" onClick={this.addRow}>{intl.get('Dodaj pozycję')}</button>
      </div>
    );
  }
}

class OrderComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      code: '',
      progress: 0,
      valid: false,
      orderSubstitutes: {},
      shortagesButtonClicked: false,
      shortagesNextPoint: false
    }
  }

  componentDidMount() {
    const { getOrderAction, authenticated, order_id, order, last_scanned, auto_select_first, material_products } = this.props
    if(authenticated && !order) getOrderAction(false, order_id)
    if(auto_select_first && order && last_scanned && CSS.escape(last_scanned).length > 0){
      let checkbox = document.querySelector('.produkt[data-barcode*="#'+CSS.escape(last_scanned)+'#"] .checkbox.mainCheckbox:not(.checked), '+
                                            '.produkt .bundleItemList tr[data-barcode*="#'+CSS.escape(last_scanned)+'#"] .checkbox:not(.checked), '+
                                            '.produkt[data-name="'+b64EncodeUnicode(last_scanned)+'"] .checkbox.mainCheckbox:not(.checked), '+
                                            '.produkt .bundleItemList tr[data-name="'+b64EncodeUnicode(last_scanned)+'"] .checkbox:not(.checked), '+
                                            '.produkt[data-code="'+CSS.escape(last_scanned)+'"] .checkbox.mainCheckbox:not(.checked), '+
                                            '.produkt .bundleItemList tr[data-code="'+CSS.escape(last_scanned)+'"] .checkbox:not(.checked)'
                                           )
      if(!checkbox){
        let code = document.querySelector('.produkt .default_code[data-code="'+CSS.escape(last_scanned)+'"]')
        if(code){
          code.closest('.produkt.ramka')
          checkbox = code.closest('.produkt.ramka').querySelector('.checkbox:not(.checked)')
        }
      }
      if(checkbox){
        let parent = checkbox.closest('.produkt.ramka')
        this.toggleCheckbox(checkbox, parent)
      }
    }

  }

  componentDidUpdate(){
    const { product_confirmation_method, shipping } = this.props
    if(['scan', 'scan_click'].includes(product_confirmation_method) && shipping === false){
      document.getElementById('scanner').focus()
    }
    this.checkAllProducts();
  }

  deselectOrder = (event) => {
    const {selectOrderAction, releaseOrderAction, order_id, pack, returnToHomeAction} = this.props
    if (pack == -1) {
      returnToHomeAction()
    } else {
      releaseOrderAction(order_id)
      selectOrderAction()
    }
  }

  doToggleCheckbox = (checkbox, parent) => {
    checkbox.classList.toggle('checked')

    document.querySelectorAll('.produkt').forEach(el => el.classList.remove('last_scanned'))
    checkbox.closest('[data-barcode]').classList.add('last_scanned')

    if(parent.querySelector('.bundleItemList')){
      if(checkbox.classList.contains('mainCheckbox')){
        document.querySelectorAll('.bundleItemList .checkbox').forEach(el => el.classList.toggle('checked', checkbox.classList.contains('checked')))
      } else {
        parent.querySelector('.product_checkbox .checkbox').classList.toggle('checked', !parent.querySelector('.bundleItemList .checkbox:not(.checked)'))
      }
    }

    this.toggleConfirmButton()
  }

  toggleCheckbox = (checkbox, parent, scrollInto = false) => {
    var cnt = parent.getAttribute('data-prods')
    const show_grouped_counter = parent.classList.contains('show_grouped_counter')
    let bundle_cnt = 0
    if(checkbox.classList.contains('multiproduct')){
      bundle_cnt = checkbox.closest('tr').getAttribute('data-qty')
      cnt = cnt+' x '+bundle_cnt
    }
    const {verify_qty, group_treshold} = this.props
    if(!verify_qty || (!parent.classList.contains('supercount') && !(bundle_cnt >= group_treshold)) ||
       checkbox.classList.contains('checked')
      ){
        this.doToggleCheckbox(checkbox, parent)
        if(scrollInto) parent.scrollIntoView(true)
    } else{
      let self = this
      if(show_grouped_counter){
        parent.querySelector('.grouped_counter__increment').click()
      } else {
        Popup.create({
          content: intl.get('Czy na pewno spakowałeś X pozycji tego SKU?', {cnt: cnt}),
          buttons: {
            right: [{
              text: intl.get('Tak'),
              key: 'enter',
              action: function () {
                Popup.close()
                self.doToggleCheckbox(checkbox, parent)
                if(scrollInto) parent.scrollIntoView(true)
              }
            },
            {
              text: intl.get('Nie'),
              className: 'cancel-button',
              action: function () {
                Popup.close()
              }
            }]
          }
        })
      }
    }
  }

   printErrorLabel = () => {
    const { order_id, printErrorLabelAction } = this.props
    Popup.create({
      content: intl.get('Na pewno chcesz drukować etykietę zastępczą?'),
      buttons: {
        right: [{
          text: intl.get('Tak'),
          key: 'enter',
          action: function () {
            Popup.close()
            printErrorLabelAction(order_id)
          }
        },
        {
          text: intl.get('Anuluj'),
          className: 'cancel-button',
          action: function () {
            Popup.close()
          }
        }]
      }
    })
  }

  handleShortagesOrder = () => {
    const { order_id , sendProductShortages } = this.props;
    const productsWithShortages = this.getProductsWithShortages();

    const shortagesOrder = {
        order_id: order_id,
        lines: productsWithShortages.map(product => ({
            line_id: product.line_id,
            product_id: product.product_id,
            shortage_qty: product.shortage_qty
        }))
    };

    sendProductShortages(shortagesOrder);
  };


  checkInputValue = () => {
    const productsWithShortages = this.getProductsWithShortages();
    const hasShortages = productsWithShortages.length > 0;

    this.setState({ shortagesNextPoint: hasShortages }, () => {
        this.toggleConfirmButton();
    });

  };

  getProductsWithShortages = () => {
    const inputs = document.querySelectorAll('.shortages-input');
    const productsWithShortages = [];

    inputs.forEach(input => {
        const quantity = parseInt(input.value);
        if (quantity > 0) {
            const productCheckbox = input.closest('.product_checkbox');
            const lineId = productCheckbox.dataset.line; 
            const productId = productCheckbox.dataset.product;

            productsWithShortages.push({
                line_id: lineId,
                product_id: productId, 
                shortage_qty: quantity
            });
        }
    });

    return productsWithShortages;
  };

  handleScanner = (event) => {
    const {scan_bundle_element_as_single_qty} = this.props
    if (event.which === 13) {
        let ean = event.target.value;
        let checkbox = false;

        const { material_products } = this.props;

        if (Array.isArray(material_products)) {
          const productMatch = material_products.find(product => product[2] === ean);
      
          if (productMatch) {
          //console.log("Kod: " + ean);
          //console.log("Wartości", productMatch[3], productMatch[4], productMatch[5], productMatch[6]);

          const selectedRow = document.querySelector('.parcels .selected');
          if (selectedRow) {
            const inputs = selectedRow.querySelectorAll('input');
            inputs[0].value = productMatch[3];  // Weight
            inputs[1].value = productMatch[4];  // Height
            inputs[2].value = productMatch[5];  // Length
            inputs[3].value = productMatch[6];  // Width
          } else {
            const addRowButton = document.getElementById('addRowButton');
            if (addRowButton) {
              addRowButton.click();

              setTimeout(() => {
                const lastRow = document.querySelector('.parcels tbody tr:last-child');
                if (lastRow) {
                  const inputs = lastRow.querySelectorAll('input');
                  inputs[0].value = productMatch[3];  // Weight
                  inputs[1].value = productMatch[4];  // Height
                  inputs[2].value = productMatch[5];  // Length
                  inputs[3].value = productMatch[6];  // Width
                }
              }, 0);
            }
          }

          event.target.value = '';
          event.preventDefault();
          event.stopPropagation();
          return false;

          }
      }


        if (ean.length > 0) {
            checkbox = document.querySelector('.produkt[data-barcode*="#' + ean + '#"]:not(.blocked_by_serial) .checkbox.mainCheckbox:not(.checked), .produkt .bundleItemList tr[data-barcode*="#' + ean + '#"] .checkbox:not(.checked)');
        }

      if (!checkbox) {
          if (event.target.id === 'scanner') {
              wrong_ean_audio.play().catch(function (error) {
                  console.error('Wystąpił błąd podczas próby odtworzenia dźwięku:', error);
              });
              Popup.create({
                  content: intl.get('Nie znaleziono produktu z tym kodem.'),
                  buttons: {
                      right: [{
                          text: 'OK',
                          className: 'ok',
                          key: 'enter',
                          action: function () {
                              Popup.close();
                              document.getElementById('scanner').value = '';
                              document.getElementById('scanner').focus();
                          }
                      }]
                  }
              });
          }
      } else {
          let parent = checkbox.closest('.produkt.ramka');
          let counterElement = parent.querySelector('.bundleItemList tr[data-barcode*="#' + ean + '#"] .smaller.counter');
          
          if (scan_bundle_element_as_single_qty && counterElement) {
              let dataCount = counterElement.getAttribute('data-count');
              let dataQtyUom = counterElement.getAttribute('data-qty-uom');
              
              let currentCount = parseInt(dataCount) || 0;
              let qtyUom = parseInt(dataQtyUom);
      
              currentCount += 1;
              counterElement.setAttribute('data-count', currentCount);
              counterElement.textContent = `${currentCount} / ${qtyUom}`;
      
              if (currentCount >= qtyUom) {
                  this.toggleCheckbox(checkbox, parent, true);
              }
          } else {
              this.toggleCheckbox(checkbox, parent, true);
          }
      
          event.target.value = '';
          if (is_local_host_or_testerp) {
              speaky_peaky_one_audio.play().catch(function (error) {
                  console.error('Wystąpił błąd podczas próby odtworzenia dźwięku:', error);
              });
          } else {
              correct_scan_audio.play().catch(function (error) {
                  console.error('Wystąpił błąd podczas próby odtworzenia dźwięku:', error);
              });
          }
      }
      event.preventDefault();
      event.stopPropagation();
      return false;
  }
}

  sizePopup = (data, field_name, options) => {
    const self = this
    let buttons = options.map(opt => ({
      text: opt[0],
      action: function () {
        Popup.close()
        data[field_name] = opt[1]
        self.handleFinishOrder(data)
      }
    }))
    buttons.push({
      text: intl.get('Anuluj'),
      className: 'cancel-button',
      action: function () {
        Popup.close()
      }
    })
    Popup.create({
      content: intl.get('Wybierz gabaryt paczki'),
      buttons: {
        right: buttons
      }
    })
  }

  handleFinishOrder(data){
    const { finishOrderAction, inventory_manage_materials, material_products, packing_zone_dont_show_summary, finishOrderSkipSummaryAction } = this.props
    if (inventory_manage_materials && material_products.length){
      Popup.plugins().materials(data, this)
      return
    }
    if (packing_zone_dont_show_summary) {
      finishOrderSkipSummaryAction(data)
    } else {
      finishOrderAction(data)
    }    
  }

  handleSubmit = (event) => {

    const { order, inpost_dostawy, orlen_dostawy, pp_dostawy, wza_dostawy, inventory_materials_shipment_package_sizes } = this.props
    const { orderSubstitutes } = this.state
    let data = {}
    let result = {}
    let order_id = order.id

    if (inventory_materials_shipment_package_sizes) {
      const carrierSelect = document.querySelector('select[name="carrier_id"]');
      const carrier_id = carrierSelect.value;
  
      const parcels = [];
      const parcelRows = document.querySelectorAll('.parcels.table tbody tr');
  
      parcelRows.forEach(row => {
          const parcel = {
              number: row.cells[0].textContent,
              weight: row.querySelector('input[name^="weight"]').value,
              height: row.querySelector('input[name^="height"]').value,
              length: row.querySelector('input[name^="length"]').value,
              width: row.querySelector('input[name^="width"]').value,
          };
  
          const sizeSelect = row.querySelector('select[name^="size"]');
          if (sizeSelect && sizeSelect.selectedIndex !== -1) {
              parcel.size = sizeSelect.options[sizeSelect.selectedIndex].text;
          }
          parcels.push(parcel);
      });
  
      result = {
          carrier_id,
          order_id,
          parcels
      };
      
      console.log(JSON.stringify(result, null, 2));
    }

    document.querySelectorAll('input').forEach(input => {
      if(input.type === 'radio' && !input.checked){ return }
      let val = input.value
      if ((val !== '') && !isNaN(val)){ val = parseInt(val) }
      if(input.type === 'checkbox'){
    	   val = input.checked
      }
      data[input.name] = val
    })

    if(orderSubstitutes){
      data['substitutes'] = Object.keys(orderSubstitutes).map(line_id => ({
        'line_id': parseInt(line_id),
        'original_qty': order.products.find(product => ( product.line_id === parseInt(line_id) )).product_uom_qty,
        'substitute_choosen': orderSubstitutes[line_id]
        }))
    }

    const serialValues = []
    document.querySelectorAll('.serial_value').forEach(val => {
      serialValues.push(JSON.parse(val.innerHTML))
    })
    if(serialValues.length > 0){
      data.lots = serialValues
    }

    const parcelSizesInput = document.getElementById('parcelSizes');
    const parcelSizesValue = parcelSizesInput ? parcelSizesInput.value : '';

    if(!inventory_materials_shipment_package_sizes) {
      if((data['carrier_id'] || order.carrier_id) &&
      inpost_dostawy.includes(data['carrier_id'] || order.carrier_id) &&
      order.shipping_order_ids.some((el) => {
        return (typeof el.date === 'undefined' || !el.date) &&
                !el.shipping_parcels_ids.some((parcel) => {
                  return parcel.size || parcelSizesValue;
                })
      })
      ){
        if (!parcelSizesValue){
            this.sizePopup(data, 'inpost_size', inpost_size_options)
          }
      } else if((data['carrier_id'] || order.carrier_id) &&
          orlen_dostawy.includes(data['carrier_id'] || order.carrier_id) &&
          order.shipping_order_ids.some((el) => {
            return (typeof el.date === 'undefined' || !el.date) &&
                  !el.shipping_parcels_ids.some((parcel) => {
                    return parcel.size || parcelSizesValue;
                  })
          })
        ){
          this.sizePopup(data, 'orlen_size', orlen_size_options)
      } else if((data['carrier_id'] || order.carrier_id) &&
          pp_dostawy.includes(data['carrier_id'] || order.carrier_id) &&
          order.shipping_order_ids.some((el) => {
            return (typeof el.date === 'undefined' || !el.date) &&
                  !el.shipping_parcels_ids.some((parcel) => {
                    return parcel.size || parcelSizesValue;
                  })
          })
        ){
          this.sizePopup(data, 'pp_size', pp_size_options)
      } else if((data['carrier_id'] || order.carrier_id) &&
          wza_dostawy.includes(data['carrier_id'] || order.carrier_id) &&
          order.shipping_order_ids.some((el) => {
            return (typeof el.date === 'undefined' || !el.date) &&
                  !el.shipping_parcels_ids.some((parcel) => {
                    return parcel.size || parcelSizesValue;
                  })
          })
        ){
          this.sizePopup(data, 'wza_size', wza_size_options)
      } else {
        this.handleFinishOrder(data);
      }
    } else {
      this.handleFinishOrder(result);
    }
  }

  handleShortagesOrSubmit = () => {
    const { shortagesButtonClicked } = this.state;
    const { inventory_materials_shipment_package_sizes } = this.props
    const inputFields = document.querySelectorAll('.parcels.table input[type="number"]');
    
    const areInputsValid = Array.from(inputFields).every(input => input.value !== '' && input.value !== '0');

    const tableBody = document.querySelector('.parcels.table tbody');
    const hasRows = tableBody && tableBody.rows.length > 0;

   if (!areInputsValid || (inventory_materials_shipment_package_sizes && !hasRows)) {
      Popup.create({
        content: 'Złe dane przesyłki lub ich brak!',
        buttons: {
          right: [{
            text: 'OK',
            className: 'ok',
            key: 'enter',
            action: () => Popup.close()
          }]
        }
      });
      return;
    }
  
    if (shortagesButtonClicked) {
      this.handleShortagesOrder();
    } else {
      this.handleSubmit();
    }
  };
  
  toggleConfirmButton = () => {
    let state = {};
    const { progress, valid, shortagesButtonClicked, shortagesNextPoint } = this.state;

    if (shortagesButtonClicked && shortagesNextPoint) {
        this.setState({ progress: 100 }, () => {
        });
        this.setState({ valid: true }, () => {
        });
    } else if (!shortagesButtonClicked || !shortagesNextPoint) {
      this.setState({ progress: 0 }, () => {
      });
      this.setState({ valid: false }, () => {
      });
      if (!document.querySelector('.produkt .mainCheckbox:not(.checked), .mainCheckbox.uwagiCheckbox:not(.checked)') &&
          !document.querySelector('.produkt .productVariantValue.proper:not(.selected)')) {
          state.valid = true;
          if (is_local_host_or_testerp) {
              speaky_peaky_two_audio.play().catch(function (error) {
                  console.error('Wystąpił błąd podczas próby odtworzenia dźwięku:', error);
              });
          } else {
              finish_packing_audio.play().catch(function (error) {
                  console.error('Wystąpił błąd podczas próby odtworzenia dźwięku:', error);
              });
          }
      } else {
          state.valid = false;
      }
      //Progress
      let all = document.querySelectorAll('.produkt .mainCheckbox, .mainCheckbox.uwagiCheckbox').length;
      let checked = 0;
      if (document.querySelector('.mainCheckbox.uwagiCheckbox.checked')) {
          checked += 1;
      }
      document.querySelectorAll('.produkt .mainCheckbox.checked').forEach((item) => {
          if (!item.querySelector('.productVariantValue.proper:not(.selected)')) {
              checked += 1;
          }
      });
      state.progress = checked / all * 100;
      if (all === 0) {
          state.progress = 100;
          state.valid = true;
          if (is_local_host_or_testerp) {
              speaky_peaky_two_audio.play().catch(function (error) {
                  console.error('Wystąpił błąd podczas próby odtworzenia dźwięku:', error);
              });
          } else {
              finish_packing_audio.play().catch(function (error) {
                  console.error('Wystąpił błąd podczas próby odtworzenia dźwięku:', error);
              });
          }
      }
    } else {
      console.log('Shortages button clicked. Blocked button!');
    }
    if (state.progress !== progress || state.valid !== valid) {
      this.setState(state);
    }
  }

  showShipping = (id) => {
    const { selectShippingAction } = this.props
    selectShippingAction(id)
  }

  newShipping = () => {
    const { selectShippingAction } = this.props
    selectShippingAction(0)
    return
  }

  deleteShipping = (shippingId) => {
    const { deleteShippingAction } = this.props
    Popup.create({
      content: intl.get('Czy na pewno chcesz usunąć przesyłkę?'),
      buttons: {
        right: [
          {
            text: intl.get('Tak'),
            className: 'ok',
            key: 'enter',
            action: function () {
              deleteShippingAction(shippingId)
            }
          },
          {
            text: intl.get('Anuluj'),
            className: 'cancel-button',
            action: function () {
              Popup.close()
            }
          }
        ]
      }
    }, true)
  }

  shippingList = () => {
    Popup.plugins().shipping(this.newShipping, this.deleteShipping, this)
  }

  noteCheckboxClick = (event) => {
    let $checkbox = event.currentTarget
    if ($checkbox.tagName === 'DIV'){
      $checkbox = $checkbox.parentElement.querySelector('.uwagiCheckbox')
    }
    $checkbox.classList.toggle('checked')
    this.toggleConfirmButton()
  }

  setOrderSubstitutes = (line_id, substitutes) => {
    const { orderSubstitutes } = this.state
    this.setState({
      orderSubstitutes: {
        ...orderSubstitutes,
        [line_id]: substitutes
      }
    })
  }

  getOrderSubstitutes = (line_id) => {
    const { orderSubstitutes } = this.state
    return orderSubstitutes[line_id]
  }

  checkAllProducts = () => {
    const { auto_check_all_products } = this.props;
    if (auto_check_all_products) {
      const checkboxes = document.querySelectorAll('.product_checkbox .checkbox');
      
      async function simulateMouseClick(el) {
        let opts = { view: window, bubbles: true, cancelable: true };
        el.dispatchEvent(new MouseEvent("mousedown", opts));
        el.dispatchEvent(new MouseEvent("mouseup", opts));
        el.dispatchEvent(new MouseEvent("click", opts));
      }
  
      for (const checkbox of checkboxes) {
        if (!checkbox.classList.contains('checked')) {
          simulateMouseClick(checkbox);
          break;
        }
      }
  
      const bundleWrappers = document.querySelectorAll('.bundleWrapper');
      for (const bundleWrapper of bundleWrappers) {
        const multiproductCheckboxes = bundleWrapper.querySelectorAll('.checkbox.multiproduct');
        for (const checkbox of multiproductCheckboxes) {
          if (!checkbox.classList.contains('checked')) {
            simulateMouseClick(checkbox);
          }
        }
      }
    }
  };

  handleShortagesButtonClick = () => {
    const checkboxes = document.querySelectorAll('.product_checkbox');
    const sumbitButton = document.querySelector('#zatwierdz');

    checkboxes.forEach(checkbox => {
      const checkboxesInsideProductCheckbox = checkbox.querySelectorAll('.checkbox');
        if (checkbox.classList.contains('product_checkbox_red')) {
            checkbox.classList.remove('product_checkbox_red');
            checkbox.querySelector('.shortages-container').remove();
            sumbitButton.classList.contains('shortages');
            sumbitButton.classList.remove('shortages');
            checkboxesInsideProductCheckbox.forEach(checkbox => {
              checkbox.style.display = 'flex';
              checkbox.style.pointerEvents = 'all';
            });
            this.setState({ shortagesButtonClicked: false }, () => {
            });
        } else {
            checkboxesInsideProductCheckbox.forEach(checkbox => {
                checkbox.style.display = 'none';
                checkbox.style.pointerEvents = 'none';
                if (checkbox.classList.contains('checked')) {
                  checkbox.classList.remove('checked');
                }
            });
            this.setState({ shortagesButtonClicked: true }, () => {
            });
            checkbox.classList.add('product_checkbox_red');
            sumbitButton.classList.add('shortages');

            const productDetails = checkbox.closest('.ramka.produkt');
            const qty = parseInt(productDetails.querySelector('.qty.f_group > div').textContent);
            const container = document.createElement('div');
            container.className = 'shortages-container';

            const addButton = document.createElement('button');
            addButton.textContent = '+';
            addButton.className = 'increment-button';
            addButton.addEventListener('click', () => {
                if (parseInt(shortagesInput.value) < qty) {
                    shortagesInput.value = parseInt(shortagesInput.value) + 1;
                    this.checkInputValue();
                }
            });
            container.appendChild(addButton);

            const shortagesInput = document.createElement('input');
            shortagesInput.type = 'number';
            shortagesInput.className = 'shortages-input';
            shortagesInput.value = "0";
            shortagesInput.min = "0";
            shortagesInput.max = qty;
            shortagesInput.disabled = true;
            container.appendChild(shortagesInput);

            const subtractButton = document.createElement('button');
            subtractButton.textContent = '-';
            subtractButton.className = 'decrement-button';
            subtractButton.addEventListener('click', () => {
                if (parseInt(shortagesInput.value) > 0) {
                    shortagesInput.value = parseInt(shortagesInput.value) - 1;
                    this.checkInputValue();
                }
            });
            container.appendChild(subtractButton);

            checkbox.appendChild(container);
        }
    });

    this.checkInputValue();
  };
  
  render() {
    const { authenticated } = this.props
    if(!authenticated) return (<Redirect to='/login' />)
    const odoo = window.ODOO.url + (window.ODOO.port?':'+window.ODOO.port:'')
    const {
      order,
      pack,
      product_confirmation_method,
      show_company,
      use_packing_zone_error_label,
      show_shop,
      confirm_note,
      carrier_selection,
      group_treshold,
      add_delivery,
      add_package,
      processing,
      product_shortages_in_packing_zone,
      inventory_materials_shipment_package_sizes,
      available_carriers,
      show_package_button_in_packing_zone,
    } = this.props
    const { progress, valid } = this.state
    return (
      <>
      <ProgressBar percent={progress}/>
      <div className="pakowanie">
        <style dangerouslySetInnerHTML={{__html: `
          #app header, #app footer{ display: none }
          #app{ display: block }
        `}} />
        <div>
          <div className="pakowanie_header">
            <button className="pull-left secondary" onClick={this.deselectOrder}>{intl.get('Wstecz')}</button>
            {product_shortages_in_packing_zone && (
              <button className="shortages-btn secondary text-red" onClick={this.handleShortagesButtonClick}>{intl.get('Braki')}</button>
            )}
            { ['scan', 'scan_click'].includes(product_confirmation_method) &&
              <div className="scanner_wrapper">
                <input id="scanner"
                       type="text"
                       name="scanner"
                       placeholder={intl.get('Wprowadź kod produktu')}
                       onKeyPress={this.handleScanner}
                       autoComplete="off"/>
              </div>
            }
            <button onClick={this.handleShortagesOrSubmit} className="pull-right" id="zatwierdz" disabled={!valid || processing} >{intl.get('Dalej')}</button>
          </div>
          {order &&
            <div className="pakowanie_content row">
              <input type="hidden" name="order_id" value={order.id}/>
              <input type="hidden" name="paczka" value={pack}/>
              <div className="col-lg-5">
                <div className="pakowanie_order ramka">
                  <h4>
                    {intl.get('Zamówienie')} {order.name}
                  </h4>
                  <div className="order_data">
                    <div className="notes f_group">
                      <label>{intl.get('Uwagi klienta')}:</label>
                      <div>
                        {confirm_note && order.note &&
                            <div className="checkbox mainCheckbox uwagiCheckbox" onClick={this.noteCheckboxClick}>
                              <Check className="check"/>
                            </div>
                        }
                        <div className="note_text" onClick={this.noteCheckboxClick}>{order.note}</div>
                      </div>
                    </div>

                    {inventory_materials_shipment_package_sizes && (
                      <div className="carrier f_group inventory_materials_shipment">
                        <div className="carriers-container">
                        </div>
                        <ParcelTable available_carriers={available_carriers} order={order} />
                      </div>
                    )}

                    {!inventory_materials_shipment_package_sizes && (
                      <div className="carrier f_group">
                        <label>{intl.get('Metoda dostawy')}:</label>
                        {carrier_selection && order.carriers && (
                          <div className="carriers_radios">
                            {order.carriers.map(carrier => (
                              <div className="carrier_radio" key={carrier.id}>
                                <div className="carrier_thumbnail" style={{ color: '#ccc', backgroundImage: `url("${odoo}/ideaerp_shipment_${order.carrier_comp}/static/img/logo.png")` }} />
                                <input
                                  type="radio"
                                  name="carrier_id"
                                  id={'carrier_' + carrier.id}
                                  value={carrier.id}
                                  defaultChecked={order.carrier_id === carrier.id ? 'checked' : ''}
                                />
                                <label
                                  className={order.carrier_id === carrier.id ? 'initial' : ''}
                                  htmlFor={'carrier_' + carrier.id}
                                  onClick={this.confirmCarrierChange}
                                  data-value={carrier.id}
                                >
                                  {carrier.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                        <div>
                          {order.carrier_name}
                          {inventory_materials_shipment_package_sizes && (
                            <select>
                              <option>1</option>
                              <option>2</option>
                            </select>
                          )}
                          <div>
                            <input type="hidden" name="carrier_id" value={order.carrier_id || ''} />
                            {order.carrier_comp && (
                              <span className="shippinglogo">
                                <img src={`${odoo}/ideaerp_shipment_${order.carrier_comp}/static/img/logo.png`} alt="carrier" /><br />
                              </span>
                            )}
                          </div>
                        </div>
                        {(add_package || add_delivery) && (
                          <div className="add_package_wrapper">
                            {add_delivery && <button id="add_delivery" onClick={this.addDelivery}>{intl.get('Dodaj przesyłkę')}</button>}
                            {add_package && <button id="add_package" onClick={this.addPackage}>{intl.get('Dodaj paczkę')}</button>}
                          </div>
                        )}
                      </div>
                    )}

                      {order.shipping_parcel_count &&
                        <div className="carrier f_group">
                          <label>{intl.get('Ilość paczek')}:</label>
                          <div>{order.shipping_parcel_count}</div>
                        </div>
                      }
                    {show_company &&
                      <div className="date_order f_group">
                        <label>{intl.get('Firma')}:</label>
                        <div>{order.company}</div>
                      </div>
                    }
                    {show_shop &&
                      <div className="date_order f_group">
                        <label>{intl.get('Sklep')}:</label>
                        <div>{order.shop_name}</div>
                      </div>
                    }
                    {order.packing_zone_cart_names.length > 0 &&
                      <div className="date_order f_group">
                        <label>{intl.get('Wózki')}:</label>
                        <div>{order.packing_zone_cart_names}</div>
                      </div>
                    }
                    <div className="date_order f_group">
                      <label>{intl.get('Data zamówienia')}:</label>
                      <div>{order.date_order}</div>
                    </div>
                    <div className="amount_total f_group">
                      <label>{intl.get('Kwota zamówienia')}:</label>
                      <div>{order.amount_total}</div>
                    </div>
                    {order.integration_login &&
                    <div className="integration_login f_group">
                      <label>{intl.get('Login klienta')}:</label>
                      <div>{order.integration_login}</div>
                    </div>
                    }
                    {order.allegro_account &&
                      <div className="allegro_account f_group">
                        <label>{intl.get('Login Allegro sprzedawcy')}:</label>
                        <div>{order.allegro_account}</div>
                      </div>
                    }
                    <div className="delivery f_group">
                      <label>{intl.get('Adres dostawy')}:</label>
                      <div>
                        <div>{order.delivery_name}</div>
                        <div>{order.delivery_street}</div>
                        <div>{order.delivery_zip} {order.delivery_city}</div>
                        {order.delivery_phone &&
                        <div>{order.delivery_phone}</div>
                        }
                        {order.delivery_email &&
                        <div>{order.delivery_email}</div>
                        }
                      </div>
                    </div>
                    <div className="invoice f_group">
                      <label>{intl.get('Potwierdzenie')}:</label>
                      <div>{order.invoice_option_doc ? intl.get(order.invoice_option_doc) : intl.get('other')}</div>
                    </div>
                    {order.warehouseman &&
                    <div className="warehouseman f_group">
                      <label>{intl.get('Osoba zbierająca')}:</label>
                      <div>{order.warehouseman}</div>
                    </div>
                    }
                  </div>
                  <div className="text-center buttons_wrap">
                   <div>
                   <input type="hidden" id="parcelSizes" />
                    {!inventory_materials_shipment_package_sizes && (
                      <button onClick={this.shippingList}>{intl.get('Przesyłki')}</button>
                    )}
                    {use_packing_zone_error_label &&
                      <button className="secondary" onClick={this.printErrorLabel}>{intl.get('Drukuj etykietę zastępczą')}</button>
                    }
                    {show_package_button_in_packing_zone &&
                      <a href={order.package_url} target="_blank" rel="noopener noreferrer">
                        <button>{intl.get('Przejdź do pakietu')}</button>
                      </a>
                    }
                   </div>
                  </div>
                </div>
              </div>
              <div className={'pakowanie_products col-lg-7'+(order.products.length>3?' many_products':'')}>
                {order.products.map(product => getProductListItem(product, this, (group_treshold || 10)))}
              </div>
            </div>
          }
        </div>
      </div>
      </>
    )
  }
}
const Order = connect(mapStateToProps, mapDispatchToProps)(OrderComponent)
export default withRouter(Order)
